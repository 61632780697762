<template>
  <!-- <b-card-code title=""> -->
    <b-tabs pills>
     <b-tab
        title="Supplier"
        active
      >

      <index />
      </b-tab>
    <b-tab
        title="Customer"
      >

      <indexCustomer />
      </b-tab>
    </b-tabs>
  <!-- </b-card-code> -->
</template>
<script>
// import BCardCode from '@core/components/b-card-code'
import { BTabs,BTab } from 'bootstrap-vue'
import index from './index.vue'
import indexCustomer from './indexCustomer.vue'


// BTabs, BCardText
export default {
  components: {
    // BCardCode,
    BTabs,
    BTab,
    index,
    indexCustomer
  },
  data () {
    return {
      options: {}
    }
  }
}

</script>
